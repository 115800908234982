import axios from "axios";
import { useEffect, useState } from "react";
import { ENTRYPOINT } from "../Endpoints";
import ImageDisplay from "./Image-preview";
import { getStyle } from "../overlay/Overlay";
import RenderFeature from "ol/render/Feature";
import { FeatureLike } from "ol/Feature";
import { CLASSIFIERS_NEW } from "../../classifiers/Classifiers";
import { useTranslation } from "react-i18next";

export const HOVERLAY_LIST = {
	kafkamessages_sup: ["average_speed", "vehicle_total_count", "heavy_vehicle_percentage"],
	kafkamessages_cms: ["file_field", "t1", "ts1dst111", "vi", "pr"],
	kafkamessages_camera: ["device_name"],
	kafkamessages_vms: ["road_lane"],
	kafkamessages_uvis: [
		"road_name",
		"road_conditions",
		"rainfall",
		"meteo",
		"road_conditions_extra_status",
		"zd_operation",
		"air_temperature_from",
		"air_temperature_to",
		"notes",
	],
	kafkamessages_uvismessage: [
		"road_name",
		"road_conditions",
		"rainfall",
		"meteo",
		"road_conditions_extra_status",
		"zd_operation",
		"air_temperature_from",
		"air_temperature_to",
		"notes",
	],
};

let LABELS = {};

export const HoverOverlayList = ({ props, feature }: { props: { [key: string]: any }; feature: FeatureLike }) => {
	const [labels, setLabels] = useState<{ [key: string]: string }>();
	const [t] = useTranslation();
	const table = props.layer.replace("public.", "") ?? props.internal?.table;
	useEffect(() => {
		LABELS[table as keyof typeof LABELS]
			? setLabels(LABELS[table as keyof typeof LABELS])
			: axios
					.post(ENTRYPOINT + "/api/fields", {
						table: table,
					})
					.then((res) => {
						LABELS = { ...LABELS, [table]: res.data };
						setLabels(res.data);
					});
	}, [table]);

	const getLr = (props: any) => {
		return props.lr_road ? `${props.lr_road}, ${props.lr_km_from}${props.lr_km_to ? `-${props.lr_km_to}` : ""}` : "-";
	};

	const getMiniTitle = () => {
		if (table !== "kafkamessages_sup") return <></>;
		const style = feature.get("internal")?.["style"] || getStyle(feature, feature.get("internal"));
		if (!style) return <></>;
		return style.piktogram.includes("c_bars_") ? (
			<p className="font-base mb-1 text-npp-grey-500 font-semibold">{t("sup20")}</p>
		) : (
			<p className="font-base mb-1 text-npp-grey-500 font-semibold">{t("sup60")}</p>
		);
	};

	return (
		<>
			{getMiniTitle()}
			<ul className="space-y-0.5 leading-relaxed">
				{(table === "kafkamessages_sup" || table === "kafkamessages_uvis" || table === "kafkamessages_uvismessage"
					? feature instanceof RenderFeature || !feature.get("internal")?.["style"]
						? getStyle(feature, feature.get("internal"))?.["attributes"]
						: props["internal"]?.["attributes"]
					: HOVERLAY_LIST[table as keyof typeof HOVERLAY_LIST]
				)
					?.filter((key: string) => props[key] ?? (props.layer.includes("kafkamessages_cms") || props.layer.includes("kafkamessages_sup")))
					?.map((key: string, i: number) =>
						key === "file_field" ? (
							<ImageDisplay key={key + i} hexData={props[key]}></ImageDisplay>
						) : (
							<li key={key + i}>
								<span className="font-semibold mr-2">{labels ? labels[key] : key}:</span>
								{key === "lr_road"
									? getLr(props)
									: CLASSIFIERS_NEW[table]?.[key]?.[props[key]] ??
									  props[key] ??
									  (props.layer.includes("kafkamessages_cms") || props.layer.includes("kafkamessages_sup") ? "Īslaicīgi nav datu" : "-")}
							</li>
						)
					)}
			</ul>
		</>
	);
};
