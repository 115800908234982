import { GlobalSettingsContext } from "../Settings";
import { useContext, useState } from "react";
import { StyleLayerProp } from "./Style-layer.repository";
import { Popover, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { ShareSVG } from "../shared/svg/share-svg";

export default function DataLayersSource(props: { group: StyleLayerProp }) {
	const { system_code } = useContext(GlobalSettingsContext);
	const [t] = useTranslation();

	const [open, setOpen] = useState(false);

	const hide = () => {
		setOpen(false);
	};

	const handleOpenChange = (newOpen: boolean) => {
		setOpen(newOpen);
	};

	return (
		<ul className="inline-flex  text-neutral-400/70 gap-3 ml-1 cursor-pointer">
			<li className="font-bold">·</li>
			<li>
				<Popover
					content={
						<div className="flex min-w-[16em]">
							<ul className="flex-grow flex items-center">
								{Array.from(new Set(props.group.layers.map((l) => l.attributions)))
									.filter((a) => !!a)
									.map((a, i) => (
										<li key={i}>{a}</li>
									))}
							</ul>
							<button onClick={hide} className="mb-auto inline-flex">
								<span className="material-icons">close</span>
							</button>
						</div>
					}
					overlayInnerStyle={{ borderRadius: ".25rem", borderWidth: "2px", borderColor: "rgb(77 79 80)" }}
					trigger="click"
					open={open}
					onOpenChange={handleOpenChange}
					placement="bottom"
				>
					<p className="underline hover:text-npp-blue-500 hover:fill-npp-blue-500 font-medium select-none">{t("source")}</p>
				</Popover>
				<Tooltip
					title={Array.from(new Set(props.group.layers.map((l) => l.attributions)))
						.filter((a) => !!a)
						.join(", ")}
					overlayClassName="font-bold"
					color="#4d4f50"
					trigger={"click"}
					overlayInnerStyle={{ padding: ".25rem .75rem", borderRadius: ".25rem", textAlign: "center" }}
				></Tooltip>
			</li>
			{system_code === "NPP" && props.group.dataSource && (
				<>
					<li className="font-bold">·</li>
					<li>
						<a target="_blank" href={props.group.dataSource} rel="noreferrer">
							<p className="underline font-medium flex items-center gap-1 hover:text-npp-blue-500 hover:fill-npp-blue-500 fill-neutral-400/70 whitespace-nowrap">
								{t("dataSources")}
								<ShareSVG className="h-3 w-3 inline fill-inherit mt-0.5 ml-0.5"></ShareSVG>
							</p>
						</a>
					</li>
				</>
			)}
		</ul>
	);
}
