import { IS_LOCALHOST } from "../map/Endpoints";
import { LayerStyle, LineStyle, PointStyle } from "../store/Layers.repository";

export const getMVTStyles = (styleLayers: LayerStyle[], merged?: boolean): [LineStyle[], PointStyle[]] => {
	const lines: LineStyle[] = styleLayers
		.map((l: LayerStyle) =>
			l.lines.map((line) => ({
				...line,
				layerName: l.name,
				groupName: l.groupName,
				maxZoom: l.zoom_to,
				minZoom: l.zoom_from,
				opacity: l.opacity ? (100 - l.opacity) / 100 : 1,
				source: merged ? "public." + l.original_table : `${!IS_LOCALHOST && l.mvt_url ? "public." : ""}${l.table}`,
				attributes: l.attributes.split(","),
				label_style: l.label_style,
				label: l.label,
				use_uvis_popups: l.use_uvis_popups,
			}))
		)
		.flat();
	const points: PointStyle[] = styleLayers
		.map((l: LayerStyle) =>
			l.points.map((point) => ({
				...point,
				layerName: l.name,
				groupName: l.groupName,
				maxZoom: l.zoom_to,
				minZoom: l.zoom_from,
				opacity: l.opacity ? (100 - l.opacity) / 100 : 1,
				source: merged ? "public." + l.original_table : `${!IS_LOCALHOST && l.mvt_url ? "public." : ""}${l.table}`,
				attributes: l.attributes.split(","),
				label_style: l.label_style,
				label: l.label,
				use_uvis_popups: l.use_uvis_popups,
			}))
		)
		.flat();
	return [lines, points];
};
