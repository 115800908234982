import { Circle as CircleStyle, Fill, Icon, Stroke, Style, Text } from "ol/style";
import { LineStyle, PointStyle } from "../store/Layers.repository";
import Feature, { FeatureLike } from "ol/Feature";
import { addEndsToLineString, iconInMiddleOfLineString } from "./Style-generator-turf";
import { Point } from "ol/geom";
import VectorTileLayer from "ol/layer/VectorTile";
import { circular } from "ol/geom/Polygon";
import shadowC from "../shared/img/shadow_c.png";
import shadowS from "../shared/img/shadow_s.png";
import plannedEvent from "../shared/img/planned_event_timer.png";
import * as olProj from "ol/proj";

export interface StyleFilter {
	filter: any;
	style: Style | Style[];
	styleDef: LineStyle | PointStyle;
}

export interface InternalFeatureParams {
	attributes: string[];
}

const compare = {
	is: (a: any, b: any) => a === b,
	is_not: (a: any, b: any) => a !== b,
	and: (a: any, b: any) => a && b,
	or: (a: any, b: any) => a || b,
	like: (a: any, b: any) => a.includes(b),
	in: (a: any, b: any) => !!b.includes(a),
	not_in: (a: any, b: any) => !b.includes(a),
	"=": (a: any, b: any) => a === b,
	"!=": (a: any, b: any) => a !== b,
	">": (a: any, b: any) => a > b,
	">=": (a: any, b: any) => a >= b,
	"<": (a: any, b: any) => a < b,
	"<=": (a: any, b: any) => a <= b,
};

const doDarkMagicArrays = (f: string[]) => {
	let array = false;
	return f.reduce((a: any[], b: any) => {
		if (b.includes("[")) {
			array = true;
			b = b.replace("[", "");
			return [...a, [b]];
		}
		if (array) {
			if (b.includes("]")) {
				b = b.replace("]", "");
				a[a.length - 1] = a[a.length - 1].filter((v: string) => v !== ",");
			}
			a[a.length - 1].push(b);
			return a;
		}
		return [...a, b];
	}, []);
};

// Used for qgis filter expressions
export const doDarkMagic = (feature: FeatureLike, styleFilter: StyleFilter) => {
	if (styleFilter.filter === true) return styleFilter.style;
	if (styleFilter.filter[0] === "other") return undefined;
	const g = (v: string) => {
		return feature.get(v);
	};
	let a;
	let f = styleFilter.filter;
	f = doDarkMagicArrays(f);
	const checkpoints = f.filter((val: string) => val.includes("(") || val.includes(")")).map((val: string) => f.indexOf(val)) as number[];
	f = f.map((val: string | string[]) => (Array.isArray(val) ? val : val.replace("(", "").replace(")", "")));
	for (let i = 0; i < f.length; ) {
		if (i + 3 > checkpoints[0]) {
			if (a && f[i] === "or") {
				break;
			} else {
				checkpoints.shift();
			}
		}

		if (compare[f[i] as keyof typeof compare]) {
			a = compare[f[i] as keyof typeof compare](
				a,
				compare[f[i + 2] as keyof typeof compare](g(f[i + 1])?.toString(), f[i + 3]?.replaceAll("'", "").replaceAll('"', "")?.toString())
			);
			i = i + 4;
		} else {
			a = compare[f[i + 1] as keyof typeof compare](g(f[i])?.toString(), f[i + 2]?.replaceAll("'", "").replaceAll('"', "")?.toString());
			i = i + 3;
		}
	}
	return a ? styleFilter.style : undefined;
};

const getLineStyle = (value: string, l: LineStyle) => {
	switch (value) {
		case "contour":
			return [
				new Style({
					stroke: new Stroke({
						color: l.color,
						width: l.width * 3,
						lineJoin: l.join_style as any,
						lineDashOffset: l.offset ?? undefined,
						lineCap: l.cap_style as any,
					}),
				}),
				new Style({
					zIndex: Infinity,
					stroke: new Stroke({
						color: "white",
						width: l.width,
						lineJoin: "round",
						lineDashOffset: l.offset ?? undefined,
						lineCap: "round",
					}),
				}),
			];
		case "dot":
			return [
				new Style({
					stroke: new Stroke({
						color: l.color,
						width: 10,
						lineJoin: "round",
						lineDashOffset: l.offset ?? undefined,
						lineCap: "round",
						lineDash: [1, 20],
					}),
				}),
			];
		default:
			return new Style({
				stroke: new Stroke({
					color: l.color,
					width: l.width,
					lineJoin: l.join_style as any,
					lineDashOffset: l.offset ?? undefined,
					lineCap: l.cap_style as any,
				}),
			});
	}
};

export const getPointShadow = (square?: boolean) => {
	const style = new Style({
		image: new Icon({
			src: square ? shadowS : shadowC,
		}),
		zIndex: -Infinity,
	});
	//@ts-expect-error
	style.isShadow_ = true;
	return style;
};

export const getPointStyle = (point: PointStyle) => {
	let style: Style[] = [];

	/* if (point?.piktogram) {
		style.push(getPointShadow(point.piktogram.split("/")[point.piktogram.split("/").length - 1].split("_")[0] === "s"));
	} */

	style.push(
		new Style({
			image: point?.piktogram
				? new Icon({
						rotateWithView: true,
						src: point.piktogram,
						size: [48, 48],
						scale: 0.75,
				  })
				: new CircleStyle({
						fill: new Fill({
							color: point?.color,
						}),
						radius: point?.size ?? 3,
				  }),
			zIndex: 10,
		})
	);

	return style;
};

export const getPlannedStyle = () => {
	let style: Style[] = [];
	const icon = new Icon({
		src: plannedEvent,
		displacement: [12, 12],
		scale: 0.33,
	});

	//@ts-expect-error
	icon.tempOpacity = 1;

	style.push(
		new Style({
			image: icon,
			zIndex: 10,
		})
	);

	//@ts-expect-error
	style[0].hideInCluster_ = true;

	return style;
};

export const strToFilter = (str: string) => {
	return str
		.replaceAll(/is not/g, "is_not")
		.replaceAll(/not in/g, "not_in")
		.split(/\s(?=(?:[^']*'[^']*')*[^']*$)/g);
};

export const addFeatureOpacity = (style: Style[], opacity: number) => {
	return style.map((s) => {
		const icon = s.getImage();
		if (icon && icon instanceof Icon) {
			icon.setOpacity(opacity);
		}
		return s;
	});
};

export const addLabel = (feature: Feature, { title, font, type }: { title: string; font: string; type: string }) => {
	return [
		new Style({
			text: new Text({
				text: feature.get(title)?.toString() ?? "",
				font: font,
				textAlign: "center",
				textBaseline: "middle",
				placement: type === "LineString" ? "line" : "point",
				offsetY: type === "Point" ? -32 : 0,
			}),
		}),
	];
};

export const addTempPoint = (feature: Feature) => {
	const temp = feature.get("t1") as string;
	return [
		feature.get("status") === "active"
			? new Style({
					image: new CircleStyle({
						rotateWithView: true,
						fill: new Fill({
							color: "#4c4e4f",
						}),
						stroke: new Stroke({
							color: "white",
							width: 4,
						}),
						radius: 16,
					}),
					text: new Text({
						rotateWithView: true,
						text: temp ? `${Number(temp) < 0 ? "" : "+"}${Number(temp).toFixed(0)}°` : "",
						justify: "center",
						offsetY: 1.5,
						textBaseline: "middle",
						fill: new Fill({
							color: "white",
						}),
						font: "bold 12px sans-serif",
					}),
			  })
			: new Style({
					image: new CircleStyle({
						fill: new Fill({
							color: "#c1c2c2",
						}),
						stroke: new Stroke({
							color: "white",
							width: 4,
						}),
						radius: 16,
					}),
					text: new Text({
						text: `-`,
						justify: "center",
						textBaseline: "middle",
						fill: new Fill({
							color: "white",
						}),
						font: "bold 14px sans-serif",
					}),
			  }),
	];
};

export const getOpacityHex = (opacity: number) => {
	switch (opacity) {
		case 1:
			return "ff";
		case 0.75:
			return "bf";
		case 0.5:
			return "80";
		case 0.25:
			return "40";
		case 0:
			return "00";
		default:
			return "";
	}
};

export const dataLayerStyle = ({
	lines,
	points,
	params,
}: {
	lines: LineStyle[];
	points: PointStyle[];
	params: { attributes: string; title: string; table: string; titleFont: string; layer: VectorTileLayer; use_uvis_popups: boolean; layerName: string };
}) => {
	const filterList: StyleFilter[] = [lines.map((l) => ({ ...l, type: "line" })), points.map((p) => ({ ...p, type: "point" }))].flat().map((styleDef) => {
		const filter = styleDef.filter === null || styleDef.filter === "" || strToFilter(styleDef.filter);

		const style = styleDef.type === "line" ? getLineStyle((styleDef as LineStyle).stroke_style, styleDef as LineStyle) : getPointStyle(styleDef as PointStyle);

		return {
			styleDef,
			filter,
			style,
		};
	});

	//FeatureLike
	return (feature: any, res: number) => {
		const filter = filterList.filter((f) => {
			return (feature.getGeometry().getType() as string).toLowerCase().includes((f.styleDef as any).type) && doDarkMagic(feature, f);
		});
		let style = filter.map((f) => f.style);

		if (params.table === "kafkamessages_cms" && filter.find((f) => f.styleDef?.piktogram?.includes("CMS") && f.styleDef.piktogram.includes("temperatūra"))) {
			style = addTempPoint(feature);
		}

		if (
			params.use_uvis_popups &&
			(params.table === "kafkamessages_uvis" || params.table === "kafkamessages_uvismessage") &&
			style.length > 0 &&
			feature.getGeometry()?.getType() === "Point"
		) {
			const color = filter.find((f) => f.styleDef.color)?.styleDef.color;
			const circGeom = circular(olProj.toLonLat((feature.getGeometry() as Point).getCoordinates(), "EPSG:3857"), feature.get("radius") * 1000).transform(
				"EPSG:4326",
				"EPSG:3857"
			);
			style = [
				style,
				new Style({
					fill: new Fill({
						color: color + "44",
					}),
					geometry: circGeom,
				}),
			].flat();
			feature.set("internal", { ...(feature.get("internal") ?? {}), circGeom: circGeom });
		} else if (feature && filter.find((f) => f.styleDef.piktogram && (f.styleDef as any).type === "line")) {
			const piktogram = filter.find((f) => f.styleDef.piktogram && (f.styleDef as any).type === "line")?.styleDef.piktogram!;
			style = [style as any, iconInMiddleOfLineString(feature, piktogram)].flat();
		}

		if (!!feature && !(feature.getGeometry() instanceof Point) && filter.find((f) => (f?.styleDef as LineStyle)?.cap_style === "line") && style) {
			const styleDef = filter.find((f) => (f?.styleDef as LineStyle)?.cap_style === "line")?.styleDef!;
			style = [...addEndsToLineString(feature, styleDef as LineStyle), style].flat();
		}

		if (!!feature && params.titleFont) {
			style = [...addLabel(feature, { title: params.title, font: params.titleFont, type: feature.getGeometry().getType() }), style].flat();
		}

		const lOpacity = params.layer.getOpacity();
		if (lOpacity !== feature.get("internal")?.["opacity"]) addFeatureOpacity(style.flat(), lOpacity);

		feature.setProperties(
			{
				...feature.getProperties(),
				internal: {
					...(feature.get("internal") ?? {}),
					style: style.flat(),
					attributes: [...params.attributes.split(",")],
					title: params.title,
					icon: filter.find((f) => f.styleDef.piktogram)?.styleDef.piktogram ?? "",
					table: params.table,
					opacity: lOpacity,
					use_uvis_popups: params.use_uvis_popups,
					layerName: params.layerName,
				},
			},
			true
		);

		return feature.getGeometry() instanceof Point ? undefined : style.flat();
	};
};
