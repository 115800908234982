import { useContext, useEffect } from "react";
import { HoverManagerContext } from "./Hover-wrapper";
import { getMapStore } from "../Map.repository";
import { useObservable } from "@ngneat/use-observable";
import { Feature, Map, Overlay } from "ol";
import { HOVERLAY_LIST } from "./Hover-overlay-list";
import { Point } from "ol/geom";
import { checkIfVisible } from "../../data-layers/layer-switcher/Cluster/Cooler-cluster-style";
import RenderFeature from "ol/render/Feature";
import { getStyle } from "../overlay/Overlay";
import { OVERLAY_MANAGER } from "../overlay/Select";
import LayerGroup from "ol/layer/Group";
import { GlobalSettingsContext } from "../../Settings";

export const HOVERLAY_MANAGER = new Overlay({
	element: document.getElementById("hoverlay")!,
	offset: [0, 4],
});

export const Hover = () => {
	const { setContext } = useContext(HoverManagerContext);
	const [props] = useObservable(getMapStore());

	const addHover = (map: Map) => {
		return map.on("pointermove", (e) => {
			const lg = map
				.getLayers()
				.getArray()
				.find((lg) => lg instanceof LayerGroup && lg.get("type") === "base");
			const overview = lg?.getLayersArray().find((l) => l.getZIndex() === 100);
			if (overview) return;

			const features = map.getFeaturesAtPixel(e.pixel, { hitTolerance: 3 });

			const f = features[0];
			let feature = undefined;
			if (
				f &&
				f.get("layer")?.replace("public.", "") === "kafkamessages_cms"
				/* (Object.keys(HOVERLAY_LIST).includes(f.get("layer")?.replace("public.", "") ?? f.get("internal")?.["table"]) ||
					!!Object.keys(HOVERLAY_LIST).find((key) => f.get("layer")?.includes(key))) */
			) {
				feature = f;
			}

			/* if (!feature && features[0]?.get("features")) {
				const feats = features[0].get("features").filter((f: Feature) => checkIfVisible(f.get("internal")?.["layerName"], map) && !!f.get("internal")?.["style"]);
				if (
					feats.length === 1 &&
					(Object.keys(HOVERLAY_LIST).includes(feats[0].get("layer").replace("public.", "")) ||
						!!Object.keys(HOVERLAY_LIST).find((key) => feats[0].get("layer")?.includes(key)))
				) {
					feature = feats[0];
				}
			} */

			if (feature instanceof RenderFeature) {
				const style = getStyle(feature, feature.get("internal"));
				if (style.groupName === "Braukšanas apstākļu brīdinājumi") {
					return;
				}
				if (
					(feature.get("layer").includes("kafkamessages_uvis") || feature.get("layer").includes("kafkamessages_uvismessage")) &&
					(feature.getType() === "Polygon" || !style.use_uvis_popups)
				) {
					feature = undefined;
				}
			}

			if (feature && OVERLAY_MANAGER.getPosition() === undefined) {
				features.shift();
				setContext({ feature: feature as Feature });
				HOVERLAY_MANAGER.setPosition(
					feature instanceof Feature && feature.getGeometry()?.getType() === "Point" ? (feature.getGeometry() as Point).getCoordinates() : e.coordinate
				);
			} else {
				HOVERLAY_MANAGER.setPosition(undefined);
			}
		});
	};

	useEffect(() => {
		if (!props.map) return;

		props.map.addOverlay(HOVERLAY_MANAGER);
		const keyEvent = addHover(props.map);

		return () => props.map!.un("pointermove", keyEvent.listener);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.map]);

	HOVERLAY_MANAGER.setElement(document.getElementById("hoverlay")!);
	return <></>;
};
