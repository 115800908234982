import { Feature, Map, getUid } from "ol";
import { Fill, Icon, Style, Text } from "ol/style";
import { BehaviorSubject, debounceTime } from "rxjs";
import png from "../../../shared/img/black-box.png";
import trippledotter from "../../../shared/img/tripple_dot.png";
import { StyleLayerItem, StyleLayerProp, getStyleItems } from "../../Style-layer.repository";

const PRESETS = [
	[{ x: 0, y: 0 }],
	[
		{ x: -20, y: 0 },
		{ x: 20, y: 0 },
	],
	[
		{ x: 0, y: 20 },
		{ x: -20, y: -20 },
		{ x: 20, y: -20 },
	],
	[
		{ x: -20, y: 20 },
		{ x: 20, y: 20 },
		{ x: -20, y: -20 },
		{ x: 20, y: -20 },
	],
];

const TEXT_FILL = new Fill({
	color: "#fff",
});

const blackRound = new Icon({
	src: png,
});

const dotStyle = new Style({
	image: new Icon({
		src: trippledotter,
		scale: 0.375,
	}),
});

const CLUSTER_STYLE_CACHE = {} as any;

export let VISIBLE_STYLE_LAYERS: StyleLayerProp[] = [];
let LAYERNAME_LIST: string[][] = [];

getStyleItems().subscribe((style) => {
	const array = style.filter((s) => s.visible);
	VISIBLE_STYLE_LAYERS = array;
	LAYERNAME_LIST = array.map((a) => a.layers.map((l) => l.name));
});

export const slogger = new BehaviorSubject<any>({});

slogger.pipe(debounceTime(500)).subscribe((log) => console.info(log));

export const checkIfVisible = (layerName: string, map?: Map) => {
	let layer: StyleLayerItem | undefined;
	VISIBLE_STYLE_LAYERS.find((sl) =>
		sl.layers.find((l) => {
			if (map) {
				if (l.visible && l.name === layerName) {
					const zoom = map.getView().getZoom()!;
					const [pointStyle] = l.points;

					if (pointStyle.maxZoom > zoom && pointStyle.minZoom < zoom) {
						layer = l;
						return true;
					}
					return false;
				}
			} else {
				if (l.visible && l.name === layerName) {
					layer = l;
					return true;
				}
				return false;
			}

			return false;
		})
	);
	return layer;
};

export const getCoolerClusterStyle = (feature: Feature, rotation: number) => {
	const props = feature.getProperties();

	//If not a cluster feature, return feature style
	if (props.features === undefined && props.internal) {
		const style = props["internal"]["style"];
		style.forEach((s: Style) => {
			const img = s.getImage();

			img?.setRotation(-rotation);
			img?.setOpacity(feature.get("opacity") === 1 ? (img as any).tempOpacity ?? feature.get("tempOpacity") : feature.get("opacity") ?? 0);
			s.getText()?.setRotation(-rotation);
		});
		return style;
	}

	const features = (props.features?.filter((f: Feature) => f.get("internal")?.["style"]) as Feature[]) ?? [];

	if (features.length > 0) {
		if (CLUSTER_STYLE_CACHE[getUid(feature) + rotation]) {
			return CLUSTER_STYLE_CACHE[getUid(feature) + rotation];
		}

		const icons: { [key: string]: Feature[] } = {};
		features.forEach((f) => {
			const internal = f.get("internal");
			icons[internal["icon"]] = [...(icons[internal["icon"]] ?? []), f];
		});
		const style: Style[] = [];

		const keys = Object.keys(icons).sort((a, b) => {
			const f1 = icons[a][0].get("internal")["layerName"];
			const f2 = icons[b][0].get("internal")["layerName"];
			let i1 = 0,
				i2 = 0;
			LAYERNAME_LIST.find(
				(list, index) =>
					!!list.find((l, i) => {
						if (l === f1) {
							i1 = index * 100 + i;
							return !!i1 && !!i2;
						}
						if (l === f2) {
							i2 = index * 100 + i;
							return !!i1 && !!i2;
						}
						return false;
					})
			);
			return i1 - i2;
		});
		let isCircle = false;
		if (keys.length === 3) {
			const internals = keys.map((key) => {
				const [feat] = icons[key];
				return feat.get("internal");
			});
			isCircle = !internals
				.flatMap((internal) => internal["style"])
				.find((st: Style) => {
					if (typeof (st.getImage() as any)?.getSrc !== "function") return false;
					const split = (st.getImage() as any)?.getSrc()?.split("/");
					return split[split.length - 1].split("_")[0] === "s";
				});
		}

		const isMore = keys.length > 4;
		const len = isMore ? 4 : keys.length;

		for (let i = 0; i < len; i++) {
			const feats = icons[keys[i]];
			const preset = PRESETS[len - 1][i];
			const internal = feats[0].get("internal");
			if (isMore && i === 3) {
				let amount = 0;
				for (let n = 3; n < keys.length; n++) {
					amount = amount + icons[keys[n]].length;
				}

				const dotter = dotStyle.getImage()!;

				[dotter].forEach((item) => {
					item.setDisplacement([preset.x, preset.y]);
					item.setRotation(-rotation);
					item.setOpacity(feats[0].get("opacity") ?? 0);
				});

				const black = blackRound.clone();
				black.setDisplacement([preset.x + 16, preset.y + 16]);
				black.setRotation(-rotation);
				black.setOpacity(feats[0].get("opacity") ?? 0);

				style.push(dotStyle);
				style.push(
					new Style({
						zIndex: Infinity,
						image: black,
						text: new Text({
							offsetX: preset.x + 16,
							offsetY: -preset.y - 15,
							text: amount > 99 ? "99" : amount.toString(),
							font: "bold 14px sans-serif",
							justify: "center",
							fill: TEXT_FILL,
							rotation: -rotation,
						}),
					})
				);
			} else {
				const fStyle = internal?.["style"]
					.filter((st: any) => !st.hideInCluster_)
					.map((st: Style) => {
						const sty = st.clone();
						sty.getImage()?.setDisplacement([preset.x, i === 0 && isCircle ? preset.y - 7 : preset.y]);
						sty.getImage()?.setRotation(-rotation);
						sty.getImage()?.setOpacity(feats[0].get("opacity") ?? 0);
						if ((internal["originalTable"] ?? internal["table"]) === "kafkamessages_cms") {
							sty.getText()?.setOffsetX(preset.x);
							sty.getText()?.setOffsetY(-preset.y + 1);
							sty.getText()?.setRotation(-rotation);
						}
						return sty;
					}) as Style[];
				if (feats.length > 1) {
					const black = blackRound.clone();
					black.setDisplacement([preset.x + 16, i === 0 && isCircle ? preset.y + 9 : preset.y + 16]);
					black.setRotation(-rotation);
					black.setOpacity(feats[0].get("opacity") ?? 0);
					style.push(
						new Style({
							zIndex: Infinity,
							image: black,
							text: new Text({
								offsetX: preset.x + 16,
								offsetY: i === 0 && isCircle ? -preset.y - 8 : -preset.y - 15,
								text: feats.length > 99 ? "99" : feats.length.toString(),
								font: "bold 14px sans-serif",
								justify: "center",
								fill: TEXT_FILL,
								rotation: -rotation,
							}),
						})
					);
				}
				style.push(...fStyle);
			}
		}

		CLUSTER_STYLE_CACHE[getUid(feature) + rotation] = style;
		return style;
	}
};
