import { useObservable } from "@ngneat/use-observable";
import { Map, Overlay } from "ol";
import RenderFeature from "ol/render/Feature";
import { useContext, useEffect } from "react";
import { getMapStore } from "../Map.repository";
import { OverlayManagerContext } from "./Overlay-wrapper";
import Feature, { FeatureLike } from "ol/Feature";
import { buffer, createEmpty, extend, intersects } from "ol/extent";
import { LineString, Point } from "ol/geom";
import { GlobalSettingsContext } from "../../Settings";
import { isMobile } from "react-device-detect";
import { getStyle } from "./Overlay";
import { HOVERLAY_MANAGER } from "../hover/Hover";
import LayerGroup from "ol/layer/Group";

export const OVERLAY_MANAGER = new Overlay({
	element: document.getElementById("overlay")!,
	offset: [0, 4],
	autoPan: { animation: { duration: 0 } },
	className: "ol-overlay-container ol-selectable z-10",
});

const getRedirectURL = (feature: FeatureLike) => {
	const internal = feature.get("internal");
	switch (feature.get("layer")?.replace("public.", "") ?? internal?.["originalTable"] ?? internal?.["table"]) {
		case "kafkamessages_uvis":
		case "kafkamessages_uvismessage":
			return window.location.origin + "/driving_conditions/" + feature.get("sipr_id");
		case "kafkamessages_cms":
		case "kafkamessages_sup":
		case "kafkamessages_vms":
		case "kafkamessages_camera":
			return window.location.origin + "/devices/edit/" + feature.get("kafka_id");
		default:
			return undefined;
	}
};

const checkClusterFeatures = (feat: Feature | RenderFeature) => {
	const bufferedArea = buffer(feat instanceof RenderFeature ? feat.getExtent() : feat.getGeometry()!.getExtent(), 2.4 * 96);
	return feat.get("features").find((f: Feature) => !intersects(f.getGeometry()!.getExtent()!, bufferedArea));
};

const zoomToCluster = (feat: Feature | RenderFeature, map: Map) => {
	const clusterMembers = feat.get("features");
	if (clusterMembers.length > 1) {
		// Calculate the extent of the cluster members.
		const extent = createEmpty();
		clusterMembers.forEach((feature: Feature) => extend(extent, feature.getGeometry()!.getExtent()));
		const view = map.getView();

		view.fit(extent, { duration: 250, padding: isMobile ? [-150, -150, -150, -150] : [10, 10, 10, 10], maxZoom: 16 });
		const beforeZoom = view.getZoom();
		if (isMobile) {
			setTimeout(() => {
				if (beforeZoom === view.getZoom()) {
					view.animate({ zoom: view.getZoom()! + 1, center: (feat.getGeometry() as Point).getCoordinates(), duration: 300 });
				}
			}, 250);
		}
	}
};

export default function SelectComponent() {
	const { setContext } = useContext(OverlayManagerContext);
	const { system_code } = useContext(GlobalSettingsContext);
	const [props] = useObservable(getMapStore());

	const addClick = (map: Map) => {
		map.on("click", (e) => {
			const lg = map
				.getLayers()
				.getArray()
				.find((lg) => lg instanceof LayerGroup && lg.get("type") === "base");
			const overview = lg?.getLayersArray().find((l) => l.getZIndex() === 100);
			if (overview) return;

			let features: FeatureLike[] = [];
			map
				.getFeaturesAtPixel(e.pixel, {
					layerFilter: (l) => l.get("type") !== "base" && l.get("id") !== "search" && l.get("id") !== "draw" && l.get("id") !== undefined,
					hitTolerance: isMobile ? 16 : 4,
				})
				.forEach((feature) => {
					const featureStyle = getStyle(feature, feature.get("internal"));
					if (
						!features.find((f) => {
							const fStyle = getStyle(f, f.get("internal"));
							return f.get("kafka_id") === feature.get("kafka_id") && feature.get("kafka_id") !== undefined && featureStyle.layer_id === fStyle.layer_id;
						})
					) {
						features.push(feature);
					}
				});

			const [feat] = features;
			console.log("features:", features);

			const style = feat instanceof RenderFeature ? getStyle(feat, feat.get("internal")) : undefined;

			if (feat && feat.get("features")?.length > 0 && map.getView().getZoom()! < 16 && checkClusterFeatures(feat)) {
				zoomToCluster(feat, map);
			} else if (
				feat &&
				getRedirectURL(feat) &&
				(feat.get("internal")["table"] !== "kafkamessages_uvis" || feat.get("internal")["table"] !== "kafkamessages_uvismessage") &&
				!(feat instanceof RenderFeature && (feat.getType() === "Polygon" || !style.use_uvis_popups)) &&
				system_code === "SIPR"
			) {
				window.open(getRedirectURL(feat), "_blank");
			} else if (features.length > 0) {
				features = features
					.flatMap((f) => f.get("features") ?? f)
					?.filter((f) => (f instanceof Feature ? f.get("internal")?.["style"] ?? getStyle(f, f.get("internal")) : f));

				setContext({ features: features as any });

				const geomType = feat.getGeometry()?.getType();

				setTimeout(() => {
					OVERLAY_MANAGER.setPosition(
						feat instanceof RenderFeature
							? feat.getFlatCoordinates().length > 2
								? e.coordinate
								: feat.getFlatCoordinates()
							: geomType === "Point"
							? (feat.getGeometry() as Point).getFlatCoordinates()
							: geomType === "LineString"
							? (feat.getGeometry() as LineString).getCoordinateAt(0.5)
							: e.coordinate
					);
				}, 50);

				HOVERLAY_MANAGER.setPosition(undefined);
			} else {
				OVERLAY_MANAGER.setPosition(undefined);
				document.getSelection()?.empty();
			}
		});
	};

	useEffect(() => {
		const map = props.map;
		if (map) {
			map.addOverlay(OVERLAY_MANAGER);
			addClick(map);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.map]);

	OVERLAY_MANAGER.setElement(document.getElementById("overlay")!);
	return <></>;
}
