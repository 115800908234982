import { useObservable } from "@ngneat/use-observable";
import { getMapStore } from "../map/Map.repository";
import { useEffect } from "react";
import { getStyleItems } from "./Style-layer.repository";
import { ENTRYPOINT } from "../map/Endpoints";
import axios from "axios";
import { getIntersection, getSize } from "ol/extent";

let visibleLayers = 1000;

export const DataLayersExtentZoom = () => {
	const [mapStore] = useObservable(getMapStore());
	const [styleLayers] = useObservable(getStyleItems());

	useEffect(() => {
		const map = mapStore.map;

		if (!map) return map;

		const groups = styleLayers.filter((s) => s.visible);

		const newVisibleLayers = groups.reduce((a, b) => a + b.layers.filter((l) => l.visible).length, 0);

		if (newVisibleLayers > visibleLayers) {
			const tables = Array.from(new Set(groups.map((g) => g.layers?.filter((l) => l.visible).map((l) => l.originalTable ?? l.table)).flat()));
			axios.post(`${ENTRYPOINT}/layer/extent`, { layers: tables }).then(({ data }) => {
				const dataExtent = data.replaceAll(" ", "").replace("[", "").replace("]", "").split(",");

				const viewExtent = map!.getView().calculateExtent();

				const intersection = getIntersection(
					dataExtent.map((v: string) => Number(v)),
					map!.getView().calculateExtent()
				);

				const a = getSize(dataExtent.map((s: string) => Number(s)));
				const b = getSize(viewExtent);

				if (!dataExtent.find((d: string) => !d || d === "" || d === "0") && viewExtent.toString() !== intersection.toString() && a[0] * a[1] < b[0] * b[1])
					map.getView().fit(
						dataExtent.map((e: string) => Number(e)),
						{
							duration: 750,
							padding: [60, 60, 60, 60],
							maxZoom: 16,
						}
					);
			});
		}

		visibleLayers = newVisibleLayers;
	}, [styleLayers, mapStore.map]);

	return <></>;
};
