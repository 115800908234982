import { useObservable } from "@ngneat/use-observable";
import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { getMapStore } from "./Map.repository";
import { Map } from "ol";
import { getStyleItems } from "../data-layers/Style-layer.repository";
import { EventsKey } from "ol/events";

let key: EventsKey;

export const UrlManager = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [styleStore] = useObservable(getStyleItems());
	const [mapStore] = useObservable(getMapStore());

	const updateSearchParams = (map: Map) => {
		const view = map.getView();
		const center = view.getCenter()!.map((c) => c.toFixed(0));
		const zoom = view.getZoom();

		const items: string[] = [];
		map
			.getAllLayers()
			.filter((l) => l.get("type") === "base")
			.forEach((l) => {
				if (l.getVisible() && l.get("id")) {
					items.push(l.get("id"));
				}
			});

		styleStore
			.flatMap((s) => [s, s.layers])
			.flat()
			.forEach((s: any) => {
				if (s.visible) {
					let id = s.id.toString();
					const opacity = [s.lines, s.points]
						.flat()
						.filter((v) => !!v)
						.find((style) => !isNaN(style.opacity))?.opacity;

					id = `${id}[${opacity ?? 0}]`;
					items.push(id);
				}
			});

		if (items.length < 2) return;

		setSearchParams(
			{ ...(Object.fromEntries([...(searchParams as any)]) as any), c: center!.toString(), z: zoom!.toString(), layers: items.toString() },
			{ replace: true }
		);
	};

	const updateCoordParams = useCallback(
		(map: Map) => {
			const view = map.getView();
			const center = view.getCenter()!.map((c) => c.toFixed(0));
			const zoom = view.getZoom();
			setSearchParams(
				{ ...(Object.fromEntries([...(searchParams as any)]) as any), c: center!.toString(), z: zoom!.toString(), layers: searchParams.get("layers")?.toString() ?? "" },
				{ replace: true }
			);
		},
		[searchParams, setSearchParams]
	);

	useEffect(() => {
		const map = mapStore.map;

		if (!map) return;

		const keyEvent = map.on("change", () => {
			updateSearchParams(map);
		});
		return () => map.un("change", keyEvent.listener);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mapStore]);

	useEffect(() => {
		const map = mapStore.map;
		if (!map) return;

		if (key) {
			map.un("moveend", key.listener);
		}
		key = map.on("moveend", () => updateCoordParams(map));
	}, [mapStore, updateCoordParams]);

	useEffect(() => {
		const map = mapStore.map;
		if (!map) return;

		updateSearchParams(map);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [styleStore, mapStore.map]);

	return <></>;
};
