// react
import { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { useObservable } from "@ngneat/use-observable";

// openlayers
import Map from "ol/Map";
import View from "ol/View";
import { ScaleLine, defaults as defaultControls, Attribution, Zoom, Rotate } from "ol/control.js";
import { MouseWheelZoom, defaults as defaultIntercations } from "ol/interaction.js";
import { Coordinate } from "ol/coordinate";
import proj4 from "proj4";
import { register } from "ol/proj/proj4";

import { ENTRYPOINT } from "./Endpoints";
import { updateMapStore } from "./Map.repository";
import { updateSearchStore } from "../search/Search.repository";
import BasicVectorLayer from "./layers/Vector-layer";
import PanZoomControl from "./controls/Pan-zoom";
import { transform } from "ol/proj";
import { fromExtent } from "ol/geom/Polygon";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { Point } from "ol/geom";
import { Feature } from "ol";
import CenterPoint from "./controls/Center-point";
import Settings from "../settings/Settings";
import { type NominatimObject, type SearchResultObject, readWKTFeature } from "./Feature";
import { getSettingStore } from "../settings/Settings.repository";
import { LanguageSwitcher } from "./controls/Language-switcher";
import { OverlayWrapper } from "./overlay/Overlay-wrapper";
import { CoordSearchError } from "./Coord-search-errors";
import { MapWebSocket } from "./websockets/Map-websocket";
import { MapDrawControlWrapper } from "./controls/Map-draw-control-wrapper";
import { MapAddControls } from "./controls/Map-add-controls";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import { point, polygon } from "@turf/helpers";
import { BaseLayerManager } from "./Base-layer-manager";
import { HoverWrapper } from "./hover/Hover-wrapper";
import { GlobalSettingsContext } from "../Settings";
import { useTranslation } from "react-i18next";
import { BubbleWrapper } from "./bubble/Bubble-wrapper";
import { CustomMap } from "./Custom-map";
import DataLayersNewLayerManager from "../data-layers/Data-layers-new-layer-manager";
import { UrlManager } from "./Url-manager";
import SearchBar from "../search/Search-bar";
import LegendComponent from "./controls/legend/Legend";
import { useSearchParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { isMobile } from "react-device-detect";
import DataLayersUvis from "../data-layers/Data-layers-uvis";
import { CoolerCluster } from "../data-layers/layer-switcher/Cluster/Cooler-cluster";
import { getRotateElement } from "./Rotate";
import { clearCache } from "../data-layers/layer-switcher/Cluster/Cooler-cluster-group";
import { ClusterSliders } from "./ClusterSliders";
import { FeatureHighlighter } from "./Feature-highlighter";

const extent = [1781135.651027596, 7172666.580156755, 3720596.984132404, 8335847.354103245];
let requests: AbortController[] = [];
export let PERMISSIONS: string[] = [];
export const MAP_CENTER = [2683314.5227, 7749846.3892];

function MapWrapper() {
	const [map, setMap] = useState<Map>();
	const [edit] = useState(window.location.href.includes("?edit="));
	const [showError, setErrorShow] = useState(false);
	const [panZoomActive, setPanZoomActive] = useState(false);

	const { system_code, use_overview, predefined_layers } = useContext(GlobalSettingsContext);
	const [searchParams] = useSearchParams();
	const [t] = useTranslation();

	const [setting] = useObservable(getSettingStore());

	useEffect(() => {
		const token = localStorage.getItem("token");
		if (token) {
			const jwt = jwtDecode(token.replace("Bearer ", ""));
			PERMISSIONS = (jwt as any).permissions as string[];
		}
	}, []);

	// create state ref that can be accessed in OpenLayers onclick callback function
	//  https://stackoverflow.com/a/60643670
	const mapRef = useRef<Map>();
	mapRef.current = map;

	// pull refs
	const mapElement = useRef<HTMLDivElement | null>(null);

	// initialize map on first render
	useEffect(() => {
		if (!mapElement.current) return;

		// default zoom, center and rotation
		let zoom = Number(searchParams.get("z") ?? (isMobile ? 5 : 8));
		let rotation = 0;

		proj4.defs("EPSG:3059", "+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9996 +x_0=500000 +y_0=-6000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs");
		register(proj4);
		// create map
		const initialMap = new CustomMap({
			target: mapElement.current,
			view: new View({
				projection: "EPSG:3857",
				center:
					searchParams
						.get("c")
						?.split(",")
						.map((c) => Number(c)) ?? MAP_CENTER,
				zoom: zoom,
				rotation: rotation,
				constrainResolution: true,
				maxZoom: 19,
			}),
			controls: defaultControls({ attribution: false, zoom: false, rotate: false }).extend([
				new ScaleLine({ units: "metric" }),
				new Attribution({ collapsible: true, tipLabel: t("attributions") ?? "" }),
				new Zoom({
					zoomInTipLabel: t("zoomIn") ?? "",
					zoomOutTipLabel: t("zoomOut") ?? "",
					className: "ol-zoom ol-zoom-sm hidden sm:block",
				}),
				new Rotate({
					tipLabel: t("tipLabel") ?? "",
					label: getRotateElement() as unknown as HTMLElement,
					className: "sm:!top-48 !top-[11em] ol-rotate",
				}),
			]),
			interactions: defaultIntercations({ mouseWheelZoom: false }).extend([
				new MouseWheelZoom({
					constrainResolution: true, // force zooming to a integer zoom
				}),
			]),
		});

		// save map and vector layer references to state
		setMap(initialMap);
		updateMapStore({ map: initialMap });

		const searchHash = window.location.hash?.split("&")[2];
		if (searchHash?.includes("q=")) {
			handdleSearchItems(decodeURI(searchHash.replace("q=", "")));
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mapElement]);

	function handlePanZoomChange(event: boolean) {
		if (!mapRef.current) return;
		setPanZoomActive(event);
	}

	function handdleSearchItems(searchTerm: any) {
		clearSearchLayer();

		searchTerm = searchTerm.trim();

		if (searchTerm === "") {
			return;
		}

		if (RegExp(/^([0-9]+(\W[0-9]+)?)(\s|,)+([0-9]+(\W[0-9]+)?)$/).test(searchTerm)) {
			if (!mapRef.current) return;
			const extentCoordinate = polygon(fromExtent(extent).getCoordinates());
			const [lat, lng] = searchTerm.includes(",") ? searchTerm.split(",") : searchTerm.split(" ");
			try {
				if (booleanPointInPolygon(transform([Number(lng), Number(lat)], "EPSG:4326", "EPSG:3857"), extentCoordinate)) {
					addPointMarker(transform([Number(lng), Number(lat)], "EPSG:4326", "EPSG:3857"));
					mapRef.current?.getView()?.animate({
						center: transform([Number(lng), Number(lat)], "EPSG:4326", "EPSG:3857"),
						duration: 750,
						zoom: 14,
					});
				} else if (booleanPointInPolygon(point([Number(lat), Number(lng)]), extentCoordinate)) {
					addPointMarker([Number(lat), Number(lng)]);
					mapRef.current?.getView()?.animate({
						center: [Number(lat), Number(lng)],
						duration: 750,
						zoom: 14,
					});
				} else {
					setErrorShow(true);
				}
			} catch {
				setErrorShow(true);
			}

			return;
		} else if (RegExp(/^[0-9,. ]*$/).test(searchTerm)) {
			setErrorShow(true);
			return;
		}

		updateSearchStore({ data: undefined, loading: true });
		const items = [
			{
				url: `${ENTRYPOINT}/api/caps/macroroadnetwork/?search=${searchTerm}&limit=50`,
				source: "LVC",
			},
			{
				url: `${ENTRYPOINT}/api/vzd/varis/nlieta/?search=${searchTerm}&limit=50`,
				source: "VZD",
			},
			{
				url: `${ENTRYPOINT}/search/nominatim/?q=${searchTerm}&countrycodes=lv`,
				source: "OSM",
			},
		];

		let loading = items.length;
		const data: any = [];
		if (requests.length > 0) {
			requests.forEach((controller) => controller.abort());
			requests = [];
		}

		items.forEach((i) => {
			const controller = new AbortController();
			axios
				.get(i.url, { signal: controller.signal })
				.then((response) => {
					(response.data.results ?? response.data).forEach((d: any) => {
						if (i.source === "OSM") {
							data.push({
								osm_type: d.osm_type,
								osm_id: d.osm_id,
								display_name: d.display_name,
								type_displayname: d.type_displayname,
								feature: readWKTFeature(d.geotext, "EPSG:4326"),
								source: i.source,
							} as NominatimObject);
						}
						if (d.the_geom)
							data.push({
								display_name: d.title ?? d.adrese,
								source: i.source,
								feature: readWKTFeature(d.the_geom.replace("SRID=3059;", "")),
							} as SearchResultObject);
					});
					loading = loading - 1;
					updateSearchStore({ data, loading: loading !== 0 });
				})
				.catch(() => {
					loading = loading - 1;
					updateSearchStore({ data, loading: loading !== 0 });
				});
			requests.push(controller);
		});
	}

	function clearSearchLayer() {
		map
			?.getLayers()
			.getArray()
			.forEach((layer) => {
				if (layer.getProperties().id === "search") {
					(layer as any).getSource().clear();
				}
			});
	}

	function addPointMarker(coordinates: Coordinate) {
		map
			?.getLayers()
			.getArray()
			.forEach((layer) => {
				if (layer.getProperties().id === "search") {
					(layer as VectorLayer<VectorSource>).getSource()?.addFeature(new Feature(new Point(coordinates)));
				}
			});
	}

	useObservable(
		getSettingStore().pipe((s) => {
			if (setting.showAttributionBtn) {
				const attributionExist = map
					?.getControls()
					?.getArray()
					.find((c) => c instanceof Attribution);
				if (!attributionExist) {
					map?.addControl(new Attribution({ collapsible: true, tipLabel: "Datu avoti" }));
				}
			} else {
				map?.getControls().forEach(function (control) {
					if (control instanceof Attribution) {
						map.removeControl(control);
					}
				});
			}
			return s;
		})
	);

	useEffect(() => {
		return () => {
			updateMapStore({ map: undefined });
			clearCache();
		};
	}, []);

	return (
		<div ref={mapElement} className={"absolute overflow-hidden inset-0 top-" + (panZoomActive ? "cursor-crosshair" : "")} id="map">
			{map && (
				<>
					<OverlayWrapper></OverlayWrapper>
					{!isMobile && <HoverWrapper></HoverWrapper>}
					{!predefined_layers && <UrlManager></UrlManager>}
					{use_overview && !isMobile && <BubbleWrapper></BubbleWrapper>}
					<BasicVectorLayer properties={{ title: "Search result", id: "search" }}></BasicVectorLayer>
					{<SearchBar searchItems={handdleSearchItems}></SearchBar>}
					{localStorage.getItem("switcher") && <LanguageSwitcher></LanguageSwitcher>}
					<CenterPoint></CenterPoint>
					{edit && <MapDrawControlWrapper></MapDrawControlWrapper>}
					<BaseLayerManager></BaseLayerManager>
					{/* <DataLayersCluster></DataLayersCluster> */}
					<CoolerCluster></CoolerCluster>
					<DataLayersUvis></DataLayersUvis>
					<DataLayersNewLayerManager></DataLayersNewLayerManager>
					{!isMobile && <FeatureHighlighter map={map}></FeatureHighlighter>}
					<LegendComponent></LegendComponent>
					<PanZoomControl handlePanZoomChange={handlePanZoomChange}></PanZoomControl>
					{system_code === "SIPR" && !edit && !predefined_layers && <MapAddControls></MapAddControls>}
					<MapWebSocket></MapWebSocket>
					<Settings></Settings>
					{showError && <CoordSearchError hide={() => setErrorShow(false)}></CoordSearchError>}
					<ClusterSliders map={map}></ClusterSliders>
				</>
			)}
		</div>
	);
}

export default MapWrapper;
