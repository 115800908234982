import { Feature } from "ol";
import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { ENTRYPOINT } from "../Endpoints";
import RenderFeature from "ol/render/Feature";
import moment from "moment";
import { useTranslation } from "react-i18next";
import ImageDisplay from "../hover/Image-preview";
import { CLASSIFIERS_NEW } from "../../classifiers/Classifiers";
import { HOVERLAY_LIST } from "../hover/Hover-overlay-list";
import { getStyle } from "./Overlay";

let LABELS = {};

export const OverlayList = ({ feature, attributes }: { feature: Feature | RenderFeature; attributes: string[] }) => {
	const [labels, setLabels] = useState<{ [key: string]: string }>();
	const [t] = useTranslation();

	useEffect(() => {
		const props = feature.getProperties();
		const table = props.layer !== "features" ? props.layer.replace("public.", "") : props.internal.table;
		LABELS[table as keyof typeof LABELS]
			? setLabels(LABELS[table as keyof typeof LABELS])
			: axios
					.post(ENTRYPOINT + "/api/fields", {
						table: table.replace("_detour", ""),
					})
					.then((res) => {
						LABELS = { ...LABELS, [table]: res.data };
						setLabels(res.data);
					});
	}, [feature]);
	const props = feature.getProperties();

	if (props?.["layer"]?.includes("kafkamessages_cms") && !!props["file_field"] && !attributes?.includes("file_field")) {
		attributes?.unshift("file_field");
	}

	const getMiniTitle = () => {
		const table = props.layer !== "features" ? props.layer.replace("public.", "") : props.internal.table;
		if (table !== "kafkamessages_sup") return <></>;
		const style = feature.get("internal")?.["style"] || getStyle(feature, feature.get("internal"));
		if (!style) return <></>;
		return style.piktogram?.includes("c_bars_") ? (
			<p className="font-base mb-1 text-npp-grey-500 font-semibold">{t("sup20")}</p>
		) : (
			<p className="font-base mb-1 text-npp-grey-500 font-semibold">{t("sup60")}</p>
		);
	};

	const altValues = (key: string, groupName: string, layer: string) => {
		switch (key) {
			case "datex_enum":
			case "event_enum":
				if (groupName === "Braukšanas apstākļu brīdinājumi" || groupName === "Driving conditions warnings") {
					return t("warningType");
				}
				return null;
			case "date_from":
				if (groupName === "Braukšanas apstākļu brīdinājumi" || groupName === "Driving conditions warnings") {
					return t("warningFrom");
				}
				if (groupName === "Masas un gabarītu ierobežojumi" || groupName === "Weight and dimensions restrictions") {
					return t("timeFrom");
				}
				return null;
			case "detour":
				if (layer.includes("detour")) {
					return t("detourNotes");
				}
				return null;
			default:
				return null;
		}
	};

	return (
		<>
			<ul className="max-h-96 !overflow-y-auto scroll-bar pr-4 mr-[1px] leading-relaxed break-words space-y-0.5 mt-4">
				{getMiniTitle()}
				{(props.layer?.includes("kafkamessages_sup")
					? (feature.get("internal")?.["attributes"] as string[]) ?? (getStyle(feature, feature.get("internal"))?.["attributes"] as string[])
					: props.layer?.includes("kafkamessages_cms")
					? HOVERLAY_LIST[(props.layer.replace("public.", "") ?? props.internal?.table) as keyof typeof HOVERLAY_LIST]
					: Object.keys(props)
				)
					?.filter((p) => attributes?.includes(p) || props.layer.includes("kafkamessages_cms") || props.layer.includes("kafkamessages_sup"))
					.sort((keyA, keyB) =>
						props.layer.includes("kafkamessages_cms") || props.layer.includes("kafkamessages_sup") ? 0 : attributes?.indexOf(keyA) > attributes?.indexOf(keyB) ? 1 : -1
					)
					.map((key) => {
						const value = props[key];
						let array: string[] = [];

						const returnValue = () => {
							return array.length > 0
								? array.map((item) => CLASSIFIERS_NEW[feature.get("layer").replace("public.", "")]?.[key]?.[item] ?? item).join(",")
								: CLASSIFIERS_NEW[feature.get("layer").replace("public.", "")]?.[key]?.[value]
								? CLASSIFIERS_NEW[feature.get("layer").replace("public.", "")]?.[key]?.[value]
								: value === "[]"
								? "-"
								: value === true || value === false || value?.toString().toLowerCase() === "false" || value?.toString().toLowerCase() === "true"
								? t(value.toString().toLowerCase())
								: moment(value, true).isValid() && value?.length > 10
								? moment(value).format("YYYY-MM-DD HH:mm")
								: !value && (props.layer.includes("kafkamessages_cms") || props.layer.includes("kafkamessages_sup"))
								? t("temporarilyUnavailable")
								: value;
						};

						const style = getStyle(feature, feature.get("internal"));

						if (
							value === null ||
							value === "" ||
							(Array.isArray(value) && value.length === 0) ||
							value === "[]" ||
							value === "[None]" ||
							value === "none" ||
							(key === "srti" && value === false)
						) {
							return <Fragment key={key}></Fragment>;
						}

						try {
							const val = JSON.parse(value.replaceAll("'", '"'));
							if (Array.isArray(val)) array = val;
						} catch (err) {}
						return key === "file_field" ? (
							<ImageDisplay key={key} hexData={props[key]}></ImageDisplay>
						) : (
							<li key={key}>
								<span style={{ whiteSpace: "nowrap" }} className="font-semibold mr-2 break-keep" title={key === "srti" ? t("srtiTooltip") + "" : ""}>
									{altValues(key, style?.groupName ?? "", props?.layer.replace("public.", "")) ?? (labels ? labels[key] : key)}:
								</span>
								{returnValue()}
							</li>
						);
					})}
			</ul>
		</>
	);
};
